<template>
  <div>
    <el-table
      ref="treeTable"
      :data="categories"
      class="category-tree"
      row-key="id"
      border
      stripe
      @row-click="rowClicked"
      @current-change="handleCurrentChange"
    >
      <!-- default-expand-all -->
      <el-table-column
        label="Наименование"
      >
        <template slot-scope="scope">
          <span class="c-pointer fs16">
            <span
              v-if="scope.row.level==3"
              class="text-primary"
            >
              <i class="el-icon-star-off" />
              {{ scope.row.name_uz }}
            </span>
            <span
              v-else-if="scope.row.level==0"
              class="font-weight-bolder fs18"
            >
              <i class="el-icon-folder" />
              {{ scope.row.name_uz }}
            </span>
            <span v-else>
              <i class="el-icon-folder" />
              {{ scope.row.name_uz }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="Boshlang'ich to'lov"
      >
        <template
          v-if="scope.row.level==3"
          slot-scope="scope"
        >
          {{ scope.row.in_advance_payment_amount?scope.row.in_advance_payment_amount: 0 | formatPrice  }}
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <span
            v-if="scope.row.level!=3"
            v-can="'warehouses.product_categories-store'"
            class="c-pointer fs18 text-primary mr-1"
            @click="$emit('addCategory',scope.row); action=true"
          >
            <i class="el-icon-circle-plus-outline" />
          </span>
          <span
            v-else-if="scope.row.level==3"
            v-can="'warehouses.product_categories-delete'"
            class="c-pointer fs18 text-danger mr-1"
            @click="$emit('delete',scope.row); action=true"
          >
            <i class="el-icon-delete-solid" />
          </span>

          <span
            class="c-pointer fs20 text-info mr-1"
            v-can="'warehouses.product_categories-update'"
            @click="$emit('edit',scope.row); action=true"
          >
            <i class="el-icon-edit" />
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      action: false,
    }
  },
  computed: {
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('node-select', val)
    },
    rowClicked(val) {
      if (!this.action) {
        this.$refs.treeTable.toggleRowExpansion(val)
      }
      setTimeout(() => {
        this.action = false
      }, 1000)
    },
  },
}
</script>
