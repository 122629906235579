<template>
  <b-card>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <b-tabs
      v-if="!isHiredWorker"
      align="left"
    >
      <b-tab
        active
        title="Барча маҳсулот турлари"
        @click="openTab(1)"
      />
      <b-tab
        title="Ёлланма ишчи маҳсулот турлари"
        @click="openTab(2)"
      />
    </b-tabs>
    <b-overlay
      :show="loading"
    >
      <TableTree
        :categories="treeNodes"
        @addCategory="addCategory"
        @delete="destroy($event.id)"
        @edit="$refs.saveForm.edit($event)"
        @node-select="selectedRow"
      />
    </b-overlay>
    <b-card-footer v-if="showFooter">
      <div
        v-if="selected"
        class="mb-2"
      >
        Тип продукта: <b> {{ selected.name_uz }}</b>
        <button
          class="ml-3 btn btn-outline-danger"
          @click="selected=null"
        >
          <span class="font-weight-bolder text-danger"><feather-icon icon="XIcon" /></span>
        </button>
      </div>
      <b-button
        v-if="isShowBtn"
        v-can="'warehouses.product_categories-store'"
        variant="primary"
        class="mr-2"
        @click="selected ? $refs.saveForm.add(selected) : $refs.saveForm.visible = true"
      >
        <feather-icon icon="PlusIcon" />Qo'shish
      </b-button>
      <b-button
        v-can="'warehouses.product_categories-update'"
        variant="outline-primary"
        class="mr-2"
        :disabled="!selected"
        @click="$refs.saveForm.edit(selected)"
      >
        <feather-icon icon="EditIcon" />{{ $t('Изменить') }}
      </b-button>
      <b-button
        v-can="'warehouses.product_categories-delete'"
        variant="outline-danger"
        class="mr-2"
        :disabled="!selected"
        @click="destroy(selected.id)"
      >
        <feather-icon icon="TrashIcon" />{{ $t('Удалить') }}
      </b-button>
    </b-card-footer>
    <!--  -->
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import TableTree from '@/views/table/tree/ElTreeTable.vue'
import Save from './save.vue'
import 'vue-tree-halower/dist/halower-tree.min.css'

export default {
  name: 'Index',
  components: {
    Save, TableTree,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      selected: null,
      filterModel: {
        for_hired_worker: 'not',
        relations: 'unitOfMeasure',
        // to_level: 2,
      },
    }
  },
  computed: {
    ...mapGetters({
      treeNodes: 'productCategory/GET_TREE',
      treeRows: 'productCategory/GET_TREE_ROWS',
    }),
    isShowBtn() {
      if (this.selected) {
        return !!(this.selected && this.selected.level != 3)
      }
      return true
    },
    showFooter() {
      if (this.filterModel.for_hired_worker == 'all' && !this.isHiredWorker) {
        return false
      }
      return true
    },
  },
  mounted() {
    this.getItems()
    if (this.isHiredWorker) {
      this.openTab(2)
    }
  },
  methods: {
    ...mapActions({
      getItemsAction: 'productCategory/tree',
      destroyAction: 'productCategory/destroy',
    }),
    select(item, isSelect) {
      this.selected = isSelect ? item : null
    },
    selectedRow(item) {
      this.selected = item
    },
    addCategory(selected) {
      console.log(selected)
      if (selected) {
        this.$refs.saveForm.add(selected)
      } else {
        this.$refs.saveForm.visible = true
      }
    },
    async getItems() {
      this.selected = null
      this.loading = true
      await this.getItemsAction(this.filterModel)
        .finally(() => { this.loading = false })
    },
    openTab(id) {
      if (id == 2) {
        this.filterModel.for_hired_worker = 'all'
      } else {
        this.filterModel.for_hired_worker = 'not'
      }
      this.getItems()
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              if (res.success) {
                showToast('success', this.$t('Успешно удалено'))
                this.getItems()
                this.selected = null
              } else {
                showToast('success', this.$t('Успешно удалено'))
              }
            })
          }
        })
    },
  },
}
</script>

<style>
  .node-title {
    font-size: 18px;
  }
</style>
