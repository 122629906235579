<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <div
          v-if="parent"
          class="mb-2"
        >
          <!-- Ushbu turga qo'shiladi -->
          {{ $t('Добавляется в эту категорию') }}: <b>{{ parent.name_uz }}</b>
        </div>
        <b-form-group
          :label="$t('Именование(uz)')"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="name_uz"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="form.name_uz"
              :state="errors.length > 3 ? false:null"
              name="name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="parent && parent.level==2"
          :label="$t('Единица измерения')"
          label-for="unit_of_measure_id"
        >
          <validation-provider
            #default="{ errors }"
            name="unit_of_measure_id"
            rules="required"
          >
            <b-form-select
              id="city_id"
              v-model="form.unit_of_measure_id"
              :state="errors.length > 0 ? false:null"
              name="name_uz"
              value-field="id"
              text-field="name_uz"
              :placeholder="$t('Единица измерения')"
              :options="unit_of_measures.data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      form: {
        id: null,
        parent_id: null,
        name_uz: null,
        unit_of_measure_id: 1,
      },
      parent: null,
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ cities: 'city/GET_ITEMS', unit_of_measures: 'resource/UNIT_OF_MEASURES' }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
          this.parent = null
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.parent_id ? this.$t('Добавить тип продукта') : this.$t('Добавить категория продукта')
    this.unitOfMeasures()
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.form.unit_of_measure_id = item.unit_of_measure_id
      this.visible = true
    },
    add(item) {
      this.form.parent_id = item.id
      this.parent = item
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.update(data)
      return this.store(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      store: 'productCategory/store', update: 'productCategory/update', unitOfMeasures: 'resource/unitOfMeasures',
    }),
  },
}
</script>

<style scoped>

</style>
